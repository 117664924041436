import topic from '../topic';
import { getNoCatch, postNoCatch } from '@api/request';

export const informAPI = {
  getInforms: params => getNoCatch(`${topic.informTopic}/get_inform`, params),
  addInforms: params => postNoCatch(`${topic.informTopic}/add_inform`, params),
  getInformById: params => getNoCatch(`${topic.informTopic}/get_inform_by_id`, params),
  getInformTran: params => getNoCatch(`${topic.informTopic}/get_inform_tran`, params),
  editInform: params => postNoCatch(`${topic.informTopic}/edit_inform`, params),
  deleteInform: params => getNoCatch(`${topic.informTopic}/delete_inform`, params),
  addContracts: params => postNoCatch(`${topic.contractTopic}/add_contracts`, params),
  exportInformById: params => getNoCatch(`${topic.informTopic}/export_inform_by_id`, params),
  add_informProd: params => postNoCatch(`${topic.informTopic}/add_informProd`, params)
};
