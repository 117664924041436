import topic from '../topic';
import { getNoCatch, postNoCatch } from '@api/request';

export const modrAPI = {
  getModrs: topic.modrTopic + '/get_modrs',
  getModrById: topic.modrTopic + '/get_modr_by_id',
  addModr: topic.modrTopic + '/add_modr',
  editModr: topic.modrTopic + '/edit_modr',
  addMtrbToModr: topic.modrTopic + '/add_mtrbToModr',
  getModrMtrbByModrId: topic.modrTopic + '/get_modr_mtrb_by_modr_id',
  deleteModrByIds: topic.modrTopic + '/delete_modr_by_ids',
  exportModrPdfById: topic.modrTopic + '/pdf/export_modr_pdf_by_id',
  syncModrToMes: topic.modrTopic + '/sync_modr_to_mes',
  getModrByMtrdId: topic.mtrbTopic + '/getModrByMtrdId',
  get_modr_statistics: topic.modrTopic + '/get_modr_statistics',
  editRemark: params => getNoCatch(`${topic.modrTopic}/editModrRemark`, params),
  editModrStat: params => getNoCatch(`${topic.modrTopic}/editModrStat`, params),
  edit_modr_statistics: params => postNoCatch(`${topic.modrTopic}/edit_modr_statistics`, params),
  add_modr_statistics: params => postNoCatch(`${topic.modrTopic}/add_modr_statistics`, params),
  exportExcel: params => getNoCatch(`${topic.modrTopic}/export_modr_statistics`, params),
  edit_confirm_shipment: params => postNoCatch(`${topic.modrTopic}/edit_confirm_shipment`, params),
  exportGetModrs: topic.modrTopic + '/export_get_modrs'
};
