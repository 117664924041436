<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16 flexHV">
        <el-button type="danger" size="small" @click="doDelete()" :disabled="!btn.delete"><i class="el-icon-delete"></i> 删除</el-button>
        <el-button type="success" size="small" @click="exportGetModrs"><i class="el-icon-top-right"></i>导出</el-button>

        <el-date-picker
          class="vg_ml_8 vg_mr_8 topDateRangeSearch"
          size="small"
          v-model="dateRange"
          type="daterange"
          value-format="timestamp"
          range-separator="至"
          start-placeholder="要求交货开始日期"
          end-placeholder="要求交货结束日期"
        />
        <el-date-picker
          class="vg_ml_8 vg_mr_8 topDateRangeSearch"
          size="small"
          v-model="dateRange2"
          type="daterange"
          value-format="timestamp"
          range-separator="至"
          start-placeholder="预计付款开始日期"
          end-placeholder="预计付款结束日期"
        />
        <el-date-picker
          class="vg_ml_8 vg_mr_8 topDateRangeSearch"
          size="small"
          v-model="dateRange3"
          type="daterange"
          value-format="timestamp"
          range-separator="至"
          start-placeholder="合同开始日期"
          end-placeholder="合同结束日期"
        />
        <el-button type="primary" size="small" icon="el-icon-search" @click="getModrsNow()"> 查询</el-button>
        <el-button type="info" size="small" class="vd_export" icon="el-icon-refresh-right" @click="buttonRefresh()"> 刷新</el-button>
        <el-button type="success" size="small" class="vd_export" @click="generateInform()">生成托收信息</el-button>
        <div style="margin-left: auto">
          <div style="display: flex">
            <div style="width: 250px">全部页货款金额累计:{{ totalForm.sum_modr_prtotal }}</div>
          </div>
          <div style="display: flex">
            <div style="width: 250px">全部页费用金额累计:{{ totalForm.sum_modr_fetotal }}</div>
          </div>
          <div style="display: flex">
            <div style="width: 250px">全部页合同金额累计:{{ totalForm.sum_modr_total }}</div>
          </div>
        </div>
      </div>
      <search-table
        ref="multiTable"
        :data="tableData"
        :tableRowKey="`modr_id`"
        :columns="tableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        :need-summation="false"
        :need-fixed-height="true"
        :page-size="50"
        :show-summary="true"
        :summariesColumns="['modr_total', 'modr_prtotal', 'modr_fetotal']"
        @select="selectRows"
        @selection-change="handleSelectionChange"
        @select-all="selectRows"
        @row-dblclick="dbClickJp"
        @getTableData="getModrsNow"
      >
        <template v-slot:status="scope">
          <div style="text-align: center">
            <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">
              {{ helper.getStatusName(scope.row.status).name }}
            </el-tag>
          </div>
        </template>
        <template v-slot:modr_stat="scope">
          <div style="text-align: center">
            <el-tag :type="getModrStatName(scope.row.modr_stat).type" size="mini">
              {{ getModrStatName(scope.row.modr_stat).name }}
            </el-tag>
          </div>
        </template>
        <template v-slot:modr_remark="scope">
          <el-tooltip class="item" effect="dark" :content="tableData[scope.$index].modr_remark" placement="top-start">
            <el-input v-model="tableData[scope.$index].modr_remark" size="small" maxlength="50" clearable>
              <template #append>
                <el-link type="primary" size="small" @click="editRemark(scope.$index)" class="vg_cursor">保存</el-link>
              </template>
            </el-input>
          </el-tooltip>
        </template>
      </search-table>
    </el-card>
  </div>
</template>

<script>
import { get, getNoCatch, post } from '@api/request';
import { modrAPI } from '@api/modules/modr';
import pubPagination from '@/components/common/pubPagination';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { tableProperties } from '@/views/SalesManagement/ModrManage/modr';
import { informAPI } from '@api/modules/inform';
import { cloneDeep } from 'lodash';
import { getDept, setTime } from '@api/public';
import { arrayToString, getArrayIds } from '@assets/js/arrayUtils';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'ModrList',
  components: {
    SearchTable,
    pubPagination
  },
  data() {
    return {
      dateRange: [],
      dateRange2: [],
      dateRange3: [],
      tableProperties: tableProperties,
      loadFlag: true,
      currentPage: 1,
      totalPage: 0,
      tableData: [],
      multiSelection: [],
      btn: {},
      excelUrl: '',
      excelName: '',
      totalForm: {}
    };
  },
  created() {
    this.getDept();
  },
  mounted() {
    const { scon_no, mtrb_no, modr_no } = this.$route.query;
    if (scon_no) this.$refs.multiTable.searchForm.scon_no = scon_no;
    if (mtrb_no) this.$refs.multiTable.searchForm.mtrb_no = mtrb_no;
    if (modr_no) this.$refs.multiTable.searchForm.modr_no = modr_no;
    this.initData();
  },
  watch: {
    $route(to, from) {
      if (from.path === '/modr_edit') {
        this.initData();
      }
    }
  },
  methods: {
    initData() {
      this.getModrs();
    },
    getModrs() {
      getNoCatch(this.$route.query.mtrb_no ? modrAPI.getModrByMtrdId : modrAPI.getModrs, this.getSearchData()).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        for (const key in data) {
          if (key.includes('sum_')) this.totalForm[key] = data[key];
          if (key === 'custAbbrList') this.tableProperties.find(x => x.label === '客户简称').options = data[key].filter(x => x);
          if (key === 'custBAbbrList') this.tableProperties.find(x => x.label === '最终客户').options = data[key];
        }
        this.btn = data.btn;
        setTimeout(() => {
          this.loadFlag = false;
        }, 500);
      });
    },
    getSearchData(val = true) {
      let searchForm = cloneDeep(this.$refs.multiTable.searchForm);
      if (val) {
        //get接口需要变成逗号隔开的字符串
        arrayToString(searchForm, 'custAbbrList');
        arrayToString(searchForm, 'custBAbbrList');
      }
      setTime(searchForm, 'startTime', 'endTime', this.dateRange);
      setTime(searchForm, 'startTime2', 'endTime2', this.dateRange2);
      setTime(searchForm, 'startTime3', 'endTime3', this.dateRange3);
      return searchForm;
    },
    // 查询方法
    getModrsNow() {
      this.loadFlag = true;
      this.getModrs();
    },
    // 刷新
    buttonRefresh() {
      this.dateRange = [];
      this.dateRange2 = [];
      this.dateRange3 = [];
      this.$refs.multiTable.resetFields();
      this.getModrsNow();
    },
    // 多选获取公司抬头信息
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: 'warning', message: '请至少选择一条数据！' });
      } else {
        this.mBox();
      }
    },
    //删除提示
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.delRow();
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
          this.$refs.multiTable.clearSelection();
        });
    },
    // 删除
    delRow() {
      const ids = [];
      this.multiSelection.map(item => {
        ids.push(item.modr_id);
      });
      post(modrAPI.deleteModrByIds, { modr_id_list: ids })
        .then(res => {
          let mg = res.data.msg;
          if (res.data.code === 0) {
            let tp = 'success';
            this.$message({ message: mg, type: tp });
            if (ids.length === this.tableData.length) {
              if (this.currentPage > 1) {
                this.currentPage = this.currentPage - 1;
                this.$refs.pubPagination.currentPage = this.currentPage;
              }
            }
            this.initData();
          } else {
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
          this.initData();
        });
    },
    dbClickJp(row) {
      const permId = this.$route.query.perm_id;
      this.jump('/modr_edit', {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: permId,
            form_id: row.modr_id
          })
        )
      });
    },
    async getDept() {
      let deptList = await getDept();
      for (let i = 0; i < this.tableProperties.length; i++) {
        if (['采购部门', '销售部门'].includes(this.tableProperties[i].label)) {
          this.tableProperties[i].options = deptList;
        }
      }
    },
    editRemark(index) {
      let { modr_id, modr_remark } = this.tableData[index];
      this.loadFlag = true;
      modrAPI.editRemark({ modr_id: modr_id, modr_remark: modr_remark }).then(({ data }) => {
        this.$message.success('保存成功!');
        setTimeout(() => {
          this.loadFlag = false;
        }, 500);
      });
    },
    //exit导出
    exportGetModrs() {
      let searchForm = this.$refs.multiTable.searchForm;
      get(modrAPI.exportGetModrs, searchForm).then(res => {
        if (res.data.code === 0) {
          this.excelUrl = this.helper.megPath(res.data.data.url);
          this.excelName = '材料采购合同列表.xlsx';
          this.helper.downloadItemC(this.excelUrl, this.excelName);
          return this.$message({ message: '导出成功', type: 'success' });
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        }
      });
    },
    generateInform() {
      if (this.multiSelection.length === 0) return this.$message({ message: '请至少选择一条数据！', type: 'warning' });
      informAPI.addInforms({ modr_ids: getArrayIds(this.multiSelection, 'modr_id', true) }).then(({ data }) => {
        this.jump('/inform_edit', {
          key: UrlEncode.encode(
            JSON.stringify({
              perm_id: 176,
              form_id: data.form_id
            })
          )
        });
      });
    },
    getModrStatName(status) {
      if (status === 0) {
        return { name: '未下单', type: 'info' };
      } else {
        return { name: '已下单', type: '' };
      }
    }
  }
};
</script>

<style scoped lang="scss"></style>
