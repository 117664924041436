import { getDateNoTime } from '@assets/js/dateUtils';

export const tableProperties = [
  { prop: 'selection', type: 'selection', labelWidth: '48px' },
  { prop: 'index', type: 'index', labelWidth: '50px', label: '序号' },
  {
    prop: 'modr_stat',
    itemType: 'select',
    label: '是否下单',
    options: [
      { value: 0, label: '未下单' },
      { value: 1, label: '已下单' }
    ],
    formatter: val => (val === 0 ? '未下单' : '已下单'),
    sortable: false,
    labelWidth: 70
  },
  {
    prop: 'email_status',
    itemType: 'select',
    label: '邮件发送状态',
    options: [
      { value: 0, label: '未发送' },
      { value: 1, label: '已发送' }
    ],
    formatter: val => (val === 0 ? '未发送' : val === 1 ? '已发送' : '暂无'),
    sortable: false
  },
  { prop: 'scon_no', itemType: 'input', label: '销售合同号', sortable: false, labelWidth: '100px' },
  { prop: 'modr_no', itemType: 'input', label: '采购合同号', sortable: false, labelWidth: '100px' },
  { prop: 'contract_nos', itemType: 'input', input: false, label: '托收合同号', sortable: false, labelWidth: '100px' },
  { prop: 'supp_abbr', itemType: 'input', label: '供应商简称', sortable: true, labelWidth: '90px' },
  { prop: 'modr_cndate', itemType: 'date', label: '合同日期', sortable: true, formatter: val => getDateNoTime(val, true), labelWidth: '100px' },
  { prop: 'modr_pedate', itemType: 'date', label: '要求交货日期', sortable: true, formatter: val => getDateNoTime(val, true), labelWidth: '100px' },
  {
    prop: 'modr_ppdate',
    itemType: 'date',
    input: false,
    label: '预付款日期',
    sortable: false,
    formatter: val => getDateNoTime(val, true),
    labelWidth: '100px'
  },
  {
    prop: 'modr_total',
    label: '合同金额',
    itemType: 'input',
    labelWidth: '120px',
    input: false,
    sortable: true,
    align: 'right'
  },
  {
    prop: 'confirm_shipment',
    label: '发货状态',
    itemType: 'select',
    options: [
      { value: 0, label: '未发货' },
      { value: 1, label: '部分发货' },
      { value: 2, label: '已发货' }
    ],
    formatter: val => {
      if (val === 0) {
        return '未发货';
      } else if (val === 1) {
        return '部分发货';
      } else if (val === 2) {
        return '已发货';
      } else {
        return '暂无';
      }
    },
    labelWidth: '80px'
  },
  // {
  //   prop: 'confirm_shipment',
  //   label: '托收状态',
  //   itemType: 'select',
  //   options: [
  //     { value: 0, label: '全部托收' },
  //     { value: 1, label: '部分托收' },
  //     { value: 2, label: '未托收' }
  //   ],
  //   formatter: val => {
  //     if (val === 0) {
  //       return '未发货';
  //     } else if (val === 1) {
  //       return '部分发货';
  //     } else if (val === 2) {
  //       return '已发货';
  //     } else {
  //       return '暂无';
  //     }
  //   },
  //   labelWidth: '80px'
  // },
  { prop: 'modr_stff_name', itemType: 'input', label: '采购经办人', sortable: false, labelWidth: '100px' },
  { prop: 'modr_remark', itemType: 'input', label: '合同注意事项', labelWidth: '120px', input: false, sortable: false },
  {
    prop: 'custAbbrList',
    itemType: 'select',
    options: [],
    multiple: true,
    label: '客户简称',
    sortable: true,
    needOtherColumn: true,
    collapseTags: true,
    formatter: row => row.cust_abbr,
    labelWidth: '110px'
  },
  {
    prop: 'custBAbbrList',
    itemType: 'select',
    options: [],
    multiple: true,
    label: '最终客户',
    sortable: false,
    needOtherColumn: true,
    collapseTags: true,
    formatter: row => row.cust_babbr,
    labelWidth: '110px'
  },
  { prop: 'cust_stff_name', itemType: 'input', label: '销售经办人', sortable: false, labelWidth: '90px' },
  {
    prop: 'cust_dept_id',
    itemType: 'select',
    label: '销售部门',
    options: [],
    needOtherColumn: true,
    formatter: val => val.cust_dept_name,
    sortable: false,
    labelWidth: '80px'
  },
  {
    prop: 'modr_wiped',
    itemType: 'select',
    label: '是否已托收',
    options: [
      { value: 0, label: '未托收' },
      { value: 1, label: '已托收' }
    ],
    formatter: val => (val === 0 ? '未托收' : '已托收'),
    sortable: false,
    labelWidth: '100px'
  },
  { prop: 'modr_fetotal', label: '费用金额', itemType: 'input', input: false, sortable: true, align: 'right', labelWidth: '120px' },
  { prop: 'modr_note', itemType: 'input', label: '合同号备注', sortable: false, labelWidth: '100px' },
  { prop: 'scon_cust_no', itemType: 'input', label: '客户订单号', sortable: false, labelWidth: '100px' },
  { prop: 'cptt_name', itemType: 'input', label: '公司抬头', labelWidth: '120px' },
  {
    prop: 'modr_payway',
    itemType: 'select',
    label: '结算方式',
    options: [
      { value: 1, label: '我司结算' },
      { value: 2, label: '工厂结算' }
    ],
    labelWidth: '100px',
    formatter: val => (val === 1 ? '我司结算' : val === 2 ? '工厂结算' : '暂无')
  },
  {
    prop: 'modr_dept_id',
    itemType: 'select',
    label: '采购部门',
    options: [],
    sortable: false,
    needOtherColumn: true,
    formatter: val => val.modr_dept_name,
    labelWidth: '100px'
  },
  { prop: 'stff_name', label: '录入人', itemType: 'input', labelWidth: '90px', sortable: false },
  {
    prop: 'create_time',
    label: '录入时间',
    itemType: 'date',
    labelWidth: '100px',
    formatter: val => getDateNoTime(val, true)
  },
  { prop: 'modc_num', label: '变更次数', itemType: 'input', input: false, sortable: false, labelWidth: '85px' },
  { prop: 'modr_prtotal', label: '货款金额', itemType: 'input', input: false, sortable: true, align: 'right', labelWidth: '120px' },
  {
    prop: 'status',
    label: '单据状态',
    itemType: 'select',
    fixed: 'right',
    options: [
      { value: 0, label: '草拟' },
      { value: 1, label: '在批' },
      { value: 2, label: '生效' }
    ]
  }
];
export const subTableProperties = [
  {
    label: '产品类型',
    prop: 'mtrb_ilk',
    itemType: 'input',
    input: false,
    sortable: true,
    labelWidth: 150,
    formatter: val => (val === 0 ? '新款' : val === 1 ? '修改' : '翻单'),
    subItem: { required: true, disabled: false, type: 'input', copyAndPaste: true }
  },
  {
    label: '客户货号',
    prop: 'cust_catalog_number',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 150,
    subItem: { required: false, disabled: true, type: 'popoverInput' }
  },
  {
    label: '中文品名',
    prop: 'mtrb_name',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 150,
    subItem: { required: false, disabled: true, type: 'popoverInput', maxLength: 100 }
  },
  {
    label: '数量',
    prop: 'mtrb_num',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 150,
    subItem: { required: false, disabled: false, maxLength: 10, type: 'input' }
  },
  {
    label: '收货工厂',
    prop: 'supp_bid',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 150,
    needOtherColumn: true,
    formatter: row => row.supp_babbr,
    subItem: { type: 'input' }
  },
  { label: '材料规格', prop: 'mtrb_spec', itemType: 'input', input: false, sortable: false, labelWidth: 150, subItem: { type: 'popoverInput' } },
  {
    label: '单位',
    prop: 'mtrb_unit',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 150
  },
  { label: '备品数量', prop: 'mtrb_bnum', itemType: 'input', input: false, sortable: false, labelWidth: 150, subItem: { type: 'input' } },
  { label: '数量合计', prop: 'mtrb_num_total', itemType: 'input', input: false, sortable: false, labelWidth: 150 },
  { label: '单价', prop: 'prod_mtrb_price', itemType: 'input', input: false, sortable: true, labelWidth: 150, subItem: { type: 'input', disabled: false } },
  { label: '采购金额(元)', prop: 'mtrb_total_price', itemType: 'input', input: false, sortable: false, labelWidth: 150 },
  {
    label: '成品采购合同号',
    prop: 'podr_id',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 150,
    needOtherColumn: true,
    formatter: row => row.podr_no,
    subItem: {
      type: 'select',
      dynamicOptions: row => {
        row.list.forEach(x => (x.value = Number(x.value)));
        return row.list;
      },
      change: (val, row, options) => {
        let temp = row.list.find(x => Number(x.value) === val);
        row.podr_no = temp.label;
        row.supp_bid = Number(temp.permValue);
        row.supp_babbr = temp.permLabel;
      },
      copyAndPaste: true
    }
  },
  {
    label: '要求交货日期',
    prop: 'modr_mtrb_pedate',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 150,
    formatter: val => getDateNoTime(val, false),
    subItem: { type: 'date', copyAndPaste: true }
  },
  {
    label: '实际交货日期',
    prop: 'modr_mtrb_redate',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 150,
    formatter: val => getDateNoTime(val, false),
    subItem: { type: 'date' }
  },
  {
    label: '发货批次',
    prop: 'modr_mtrb_remark',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 150,
    subItem: { type: 'popoverInput', disabled: true }
  },
  {
    label: '包材品类',
    prop: 'mtrb_pctype',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 150
  },
  { label: '材料编号', prop: 'mtrb_no', itemType: 'input', input: false, sortable: false, labelWidth: 150 }
];
